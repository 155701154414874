import { Box } from "@mui/material";
import { HeadFC } from "gatsby";
import * as React from "react";
import { Logo } from "../components/Logo";
import { MainBox } from "../components/MainBox";
import "../styles/styles.scss";

const NotFoundPage = () => {
  return (
    <MainBox>
      <Logo />
      <Box component="p" className="description">
        お探しのページが見つかりません。
      </Box>
    </MainBox>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;
